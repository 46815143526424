<template>
  <div id="page-livereviews">
      <v-container>
        <div v-html="liveReview" style="text-align: left"></div>
      </v-container>
  </div>
</template>

<script>
export default {
  name: "LiveReviews",
  data() {
    return {
      liveReview: null
    }
  },
  mounted() {
    this.getLiveReviewsData()
  },
  methods: {
    getLiveReviewsData() {
      window.axios.get('/api/v1/live-review').then((res) => {
        this.liveReview = res.data.data
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>

</style>